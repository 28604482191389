<template>
  <div class='container-fluid'>
    <div class='row row--grid'>
      <!-- breadcrumb -->
      <div class='col-12'>
        <ul class='breadcrumb'>
          <li class='breadcrumb__item'>
            <router-link to='/ja'>ホーム</router-link>
          </li>
          <li class='breadcrumb__item breadcrumb__item--active'>個人情報保護</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <!-- title -->
      <div class='col-12'>
        <div class='main__title privacy-text'><p class='ls2 lh6 bs5 ts4'><em class='emphasis'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]('https://ailearn.space'以下'AI
          Teading')</font></font></em><font style='vertical-align: inherit;'><font
          style='vertical-align: inherit;'>は、「個人情報保護法」第30条により情報主体の個人情報を保護し、これに関する苦情を迅速かつ円滑に処理できるようにするために、次のように個人情報処理方針を樹立・公開します。</font></font></p>
          <p class='ls2'></p><br>
          <p class='sub_p mgt30'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第1条(個人情報の処理目的) </font></font><br><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>[コーディングティーディング]が個人情報保護法第32条により登録・公開する個人情報ファイルの処理目的は次のとおりです。</font></font></strong></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. 個人情報ファイル名 : Ai Teading 個人情報ファイル</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>個人情報の処理目的：プログラム使用のための情報収集</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>収集方法：生成情報収集ツールによる収集</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>保有根拠：プログラムユーザーの同意</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>保有期間：遅滞なく破棄</font></font></li><!--&lt;li&gt;관련법령 :&lt;/li&gt;--></ul>
          <br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第2条(個人情報影響評価遂行結果)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>なし</font></font></p><br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第3条(個人情報の第3者提供に関する事項)</font></font></strong><br><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'> ① </font></font><em class='emphasis'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]</font></font></em><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>は、個人情報を第1条(個人情報の処理目的)で明示した範囲内でのみ処理し、情報主体の同意、法律の特別な規定等「個人情報保護法」第17条及び第18条に該当する場合のみ、個人情報を第三者に提供します。</font></font></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② </font></font><span class='colorLightBlue'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]</font></font></span><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>は、次のように個人情報を第三者に提供しています。</font></font></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. [コーディングティーディング]</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>個人情報を提供される者：コーディングティーディング</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>提供される者の個人情報利用目的：サービス利用記録、接続ログ、クッキー</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>提供される者の保有。利用期間：目的達成後すぐに破棄</font></font></li>
          </ul>
          <br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第4条(個人情報の破棄手続及び破棄方法)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>①「コーディングティーディング」は、個人情報保有期間の経過、処理目的達成など個人情報が不要になったときは、遅滞なく当該個人情報を破棄します。</font></font><br><br><font
            style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>②情報主体から同意された個人情報保有期間が経過したり、処理目的が達成されたにもかかわらず、他の法令により個人情報を保存し続けなければならない場合には、当該個人情報を別のデータベース（DB）に移すか、保管場所を別にして保存します。</font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1.法令根拠：</font></font><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>2.保存する個人情報項目：接続情報、使用履歴</font></font><br><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>③個人情報の破棄の手順および方法は次のとおりです。</font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. 破棄手続き</font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]は破棄事由が発生した個人情報を選定し、[コーディングティーディング]の個人情報保護責任者の承認を受けて個人情報を破棄します。</font></font><br></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>2. 破棄方法</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>電子ファイル形式の情報は、記録を再生できない技術的な方法を使用します。</font></font></p>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第5条(情報主体と法定代理人の権利・義務及びその行使方法に関する事項)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>①情報主体は、コーディングティーディングについていつでも個人情報閲覧・訂正・削除・処理停止要求等の権利を行使することができます。</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>②第1項による権利行使は、コーディングティーディングについて「個人情報保護法」施行令第41条第1項により書面、電子メール、模写送信(FAX)等を通じて行うことができ、コーディングティーディングはこれに対して遅滞なく措置します。</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>③第1項による権利行使は、情報主体の法定代理人や委任を受けた者等代理人を通じて行うことができます。この場合「個人情報処理方法に関する告示(第2020-7号)」別紙第11号書式に従って委任状を提出する必要があります。</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>④個人情報閲覧及び処理停止要求は、「個人情報保護法」第35条第4項、第37条第2項により情報主体の権利が制限されることがあります。</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>⑤個人情報の訂正及び削除要求は、他の法令でその個人情報が収集対象として明示されている場合には、その削除を要求することはできません。</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>⑥コーディングティーディングは、情報主体の権利に応じた閲覧の要求、訂正・削除の要求、処理停止の要求時閲覧など要求をした者が本人であるか正当な代理人であるかを確認します。</font></font></p>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第6条(個人情報の安全性確保措置に関する事項) </font></font><em class='emphasis'><br><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]</font></font></em><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>は、個人情報の安全性確保のために次のような措置をとっています。</font></font></strong></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1. 接続記録の保管及び偽造防止</font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>個人情報処理システムに接続した記録を少なくとも1年以上保管、管理しており、ただし、5万人以上の情報主体に関して個人情報を追加したり、固有識別情報又は機密情報を処理する場合には2年以上保管、管理しています。</font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>また、接続記録が偽造や盗難、紛失しないようにセキュリティ機能を使用しています。</font></font><br><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>個人情報へのアクセス制限</font></font><br><font
            style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>個人情報を処理するデータベースシステムへのアクセス権の付与、変更、抹消を通じて個人情報へのアクセス制御のために必要な措置をしており、侵入遮断システムを利用して外部からの無断アクセスを制御しています。</font></font><br><br></p><br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第7条(個人情報を自動的に収集する装置の設置・運営及びその拒否に関する事項)</font></font></strong></p>
          <p class='ls2'><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>①コーディングティーディングは、利用者に個別のカスタマイズサービスを提供するために利用情報を保存し、随時呼び出すクッキーを使用します。</font></font><br><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>② クッキーはウェブサイトを運営するのに利用されるサーバ(http)が利用者のコンピュータブラウザに送る少量の情報であり、利用者のPCコンピュータ内のハードディスクに保存されることもあります。</font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>行く。</font><font
            style='vertical-align: inherit;'>クッキーの使用目的：利用者が訪問した各サービスとウェブサイトに対する訪問及び利用形態、人気検索語、セキュリティ接続の有無などを把握し、利用者に最適化された情報提供のために使用されます。</font></font><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>私。</font><font style='vertical-align: inherit;'>クッキーのインストール・運営及び拒否：ウェブブラウザ上部のツール＞インターネットオプション＞個人情報メニューのオプション設定により、クッキーの保存を拒否できます。</font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>だ。</font><font style='vertical-align: inherit;'>クッキーの保存を拒否すると、カスタマイズされたサービスの利用に問題が発生する可能性があります。</font></font></p>
          <p class='sub_p mgt30 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第8条（個人情報保護責任者に関する事項）</font></font></strong><br><br></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>①</font></font><span class='colorLightBlue'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>コーディングティーディング</font></font></span><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>は個人情報処理に関する業務を総括して責任があり、個人情報処理に関する情報主体の不満処理及び被害救済等のために以下のように個人情報保護責任者を指定しています。</font></font></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>▶個人情報保護責任者</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>氏名：コーディングティーディング</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>役職：なし</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>職級：なし</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>連絡先：codingteading@gmail.com</font></font></li>
          </ul>
          <p class='sub_p'><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>②情報主体は、コーディングティーディングのサービス（または事業）を利用しながら発生したすべての個人情報保護関連の問い合わせ、苦情処理、被害救済などに関する事項を個人情報保護責任者および担当部署にお問い合わせいただけます。</font><font
            style='vertical-align: inherit;'>コーディングティーディングは、情報主体の問い合わせに遅滞なく回答し処理します。</font></font></p>
          <p class='sub_p mgt30 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第9条（個人情報の閲覧請求を受付・処理する部署）</font></font></strong><br><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>情報主体は、「個人情報保護法」第35条による個人情報の閲覧請求を以下の部署にすることができます。</font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]は、情報主体の個人情報閲覧請求が迅速に処理されるよう努めます。</font></font>
          </p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>▶個人情報閲覧請求受付・処理部署</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>担当者：コーディングティーディング</font></font></li>
            <li><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>連絡先：codingteading@gmail.com</font></font></li>
          </ul>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第10条(情報主体の権益侵害に対する救済方法)</font></font><em class='emphasis'></em></strong></p><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>情報主体は、個人情報侵害による救済を受けるために個人情報紛争調整委員会、韓国インターネット振興院個人情報侵害届出センターなどに紛争解決や相談などを申請することができます。</font><font
            style='vertical-align: inherit;'>この他、その他個人情報侵害の届出、相談については下記の機関にお問い合わせください。</font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>1.個人情報紛争調整委員会：（局番なし）1833-6972（www.kopico.go.kr）</font></font><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>2.個人情報侵害届出センター：（局番なし）118（privacy.kisa.or.kr </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>）
            (局番なし) 1301 (www.spo.go.kr) </font></font><br><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>4. 警察庁：(局番なし) 182 (ecrm.cyber.go.kr) </font></font><br><br><font
            style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>「個人情報保護法」第35条(個人情報の閲覧)、第36条(個人情報の訂正・削除)、第37条(個人情報の処理停止等)の規定による要求に対して公共機関の長が行った処分又は不作為により権利又は利益の侵害を受けた者は、行政審判法が定めるバーによっては、行政審判を請求することができます。</font></font><br><br><font
            style='vertical-align: inherit;'><font style='vertical-align: inherit;'>※行政審判について詳しくは、中央行政審判委員会（www.simpan.go.kr）ホームページをご覧ください。</font></font><br><br>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第11条（映像情報処理機器の運営・管理に関する事項）</font></font><br><br><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>① </font></font><em class='emphasis'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>[コーディングティーディング]</font></font></em><font style='vertical-align: inherit;'><font
            style='vertical-align: inherit;'>は、以下のように映像情報処理機器を設置・運営しています。</font></font></strong></p>
          <p class='sub_p mgt10'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>なし</font></font></p>
          <p class='lh6 bs4'><strong><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>第12条(個人情報処理方針変更)</font></font><em class='emphasis'></em></strong><br><br></p><br>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>①この個人情報処理方針は2023年1月1から適用されます。</font></font></p>
          <p class='sub_p'></p>
          <p class='sub_p'></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>②以前の個人情報処理方針は、以下で確認できます。</font></font></p>
          <p class='sub_p'><font style='vertical-align: inherit;'><font style='vertical-align: inherit;'>なし</font></font></p></div>
      </div>
      <!-- end title -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'JA_Privacy',
  data: function() {
    return {}
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250)
  }
}
</script>
